import { makeRequest } from './make-request';
import { fetchWithCache, fetchWithPromise } from './fetch-with-cache';

export async function createAptPageStyle(data) {
  const url = data?.config?.css?.url;
  if (url) {
    data.config.css.url = transformUrlBack(url);
  }
  return makeRequest('post', 'appointment/page-styles', data);
}

export async function cloneAptPageStyle(id) {
  return makeRequest('post', `appointment/page-style/clone/${id}`, {});
}

export async function createAptPage(data) {
  return makeRequest('post', 'appointment/page/new', data);
}

export async function createAccessToken(data) {
  if (!data.originDomain) {
    delete data.originDomain;
  }
  return makeRequest('post', 'appointment/page/token', data);
}

export async function getAppointmentStyleTypes() {
  const { data, errorMessage } = await fetchWithCache('get-apt-style-types', 'appointment/page-styles/types');
  return { types: data, errorMessage };
}

async function _aptPageLogin(styleTypeCode, styleData, styleId, crmSettingsId, accessToken) {
  const { data, errorMessage } = await makeRequest('post', 'appointment/page/auth', {
    mock: !accessToken,
    styleTypeCode,
    styleData,
    styleId,
    crmSettingsId,
    token: accessToken,
    parentUrl: window.top.location.href
  });
  if (errorMessage) {
    return { errorMessage };
  }
  const { oneTimeToken, url } = data;
  return { oneTimeToken, url };
}
let loginPromise;
async function aptPageLoginGeneral(styleTypeCode, styleData, styleId, crmSettingsId, accessToken) {
  if (loginPromise) {
    return loginPromise;
  }
  loginPromise = _aptPageLogin(styleTypeCode, styleData, styleId, crmSettingsId, accessToken);
  const result = await loginPromise;
  loginPromise = undefined;
  return result;
}

export async function mockAptPageLogin(styleTypeCode, styleData, styleId, crmSettingsId) {
  return aptPageLoginGeneral(styleTypeCode, styleData, styleId, crmSettingsId);
}

export async function aptPageLogin(accessToken) {
  return aptPageLoginGeneral(undefined, undefined, undefined, undefined, accessToken);
}

export async function getAptPageStylesList(limit, name, page = 0, orderBy, orgId) {
  const key = `get-apt-page-styles-list-${limit},${page},${name},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page-styles', {
    limit,
    page,
    name,
    orderBy,
    orgId
  });
  if (data.rows) {
    for (const row of data.rows) {
      const url = row.config?.css?.url;
      if (url) {
        row.config.css.url = url.replace(
          'https://cdn.heavyset.tech',
          process.env.REACT_APP_HEAVYSET_API + '/image-bucket/wasabi'
        );
        row.config.css.url = row.config.css.url.replace(
          'https://appointment-page-images.b-cdn.net',
          process.env.REACT_APP_HEAVYSET_API + '/image-bucket/wasabi'
        );
      }
    }
  }
  return data ? { rows: data.rows, totalCount: data.totalCount } : { errorMessage };
}

export async function getAptPageList(limit, name, page = 0, orderBy, orgId) {
  const key = `get-apt-page-list-${limit},${page},${name},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page/all', {
    limit,
    page,
    name,
    orderBy,
    orgId
  });
  return data ? { rows: data.rows, totalCount: data.totalCount } : { errorMessage };
}

export async function getAccessTokenList(limit, name, page = 0, orderBy, orgId) {
  const key = `get-acc-token-list-${limit},${page},${name},${orderBy},${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'appointment/page/tokens', {
    limit,
    page,
    name,
    orderBy,
    orgId
  });
  return data ? { rows: data.rows, totalCount: data.totalCount } : { errorMessage };
}

export async function deleteAptPageStyle(id) {
  const { data, errorMessage } = await makeRequest('delete', 'appointment/page-styles', undefined, id);
  return data || { errorMessage };
}

export async function deleteAptPage(id) {
  const { data, errorMessage } = await makeRequest('delete', 'appointment/page/id', undefined, id);
  return data || { errorMessage };
}

export async function deleteAccessToken(id) {
  const { data, errorMessage } = await makeRequest('delete', 'appointment/page/token', undefined, id);
  return data || { errorMessage };
}

export async function getAptPageStyle(id) {
  const { data, errorMessage } = await fetchWithPromise('get-apt-pg-style', `appointment/page-styles/${id}`);
  return data || { errorMessage };
}

export async function getAptPage(id) {
  const { data, errorMessage } = await fetchWithPromise('get-apt-pg', `appointment/page/id/${id}`);
  return data || { errorMessage };
}

function transformUrlBack(url) {
  const searchText = '/image-bucket/wasabi';

  if (url.includes(searchText)) {
    const fileName = url.split(searchText)[1].substring(1); // Extracting the file name
    return `https://cdn.heavyset.tech/${fileName}`;
  }

  return url;
}

export async function updateAptPageStyle(update, id) {
  const url = update?.config?.css?.url;
  if (url) {
    update.config.css.url = transformUrlBack(url);
  }
  const { data, errorMessage } = await makeRequest('put', 'appointment/page-styles', update, id);
  return data || { errorMessage };
}

export async function updateAptPage(update, id) {
  const { data, errorMessage } = await makeRequest('put', 'appointment/page/id', update, id);
  return data || { errorMessage };
}
