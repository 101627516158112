import axios from 'axios';
import { getAccessToken } from '../utils';

let vlPromises = {};
async function verifyCollection(crmTypeId, crmSettingsId, settings, path) {
  const key = `${crmSettingsId || settings?.clientId}-${path}`;
  try {
    const url = `${process.env.REACT_APP_HEAVYSET_API}/crm-verify/${path}`;
    const body = { crmTypeId, crmSettingsId, settings };
    if (vlPromises[key]) {
      const result = await vlPromises[key];
      const { pass, message } = result.data;
      return { pass, message };
    }
    vlPromises[key] = axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
    const result = await vlPromises[key];
    delete vlPromises[key];
    const { pass, message } = result.data;
    return { pass, message };
  } catch (ex) {
    vlPromises = {};
    return { errorMessage: ex.response?.data?.message || ex.message };
  }
}

export async function verifyLeadCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'lead-collection');
}

export async function verifyProspectCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'prospect-collection');
}

export async function verifyLeadSourceCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'lead-source-collection');
}

export async function verifyTimeBlockCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'time-block-collection');
}

export async function verifyAppointmentCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'appointment-collection');
}

export async function verifyZipCodeCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'zip-code-collection');
}

export async function verifySmsCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'sms');
}

export async function verifyEmailCollection(crmTypeId, crmSettingsId, settings) {
  return verifyCollection(crmTypeId, crmSettingsId, settings, 'email');
}
